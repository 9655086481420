import React from 'react';
import { Link } from 'react-router-dom';
import {
  LinkedinOutlined,
  FacebookOutlined,
  TwitterOutlined,
  GithubOutlined,
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined
} from '@ant-design/icons';
import { Row, Col, Divider } from 'antd';
import logo1Bis from '../../assets/images/safari/france_num.jpg';
import logo3Bis from '../../assets/images/safari/cii_agrement_logo.png';
import QualiopiLogo from '../../assets/images/qualiopi.png';
import '../../CSS/footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <Row
        className="footer-top"
        justify="space-around"
        align="top"
        gutter={[8, 16]}
      >
        <Col xs={24} md={{ span: 12, offset: 0 }} lg={{ span: 7, offset: 0 }}>
          <Row type="flex" justify="center" gutter={[8, 16]}>
            <Col xxl={{ span: 8 }} xs={{ span: 12 }} md={{ span: 12 }}>
              <a href="https://www.francenum.gouv.fr/france-num">
                <img
                  src={logo1Bis}
                  alt="FranceNum"
                  style={{ OObjectFit: 'contain' }}
                />
              </a>
            </Col>
            <Col xxl={{ span: 8 }} xs={{ span: 12 }} md={{ span: 12 }}>
              <a href="https://www.entreprises.gouv.fr/fr/entrepreneuriat/aides-et-financement/credit-d-impot-innovation">
                <img
                  src={logo3Bis}
                  alt="CII"
                  style={{ OObjectFit: 'contain' }}
                />
              </a>
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          md={{ span: 12, offset: 0 }}
          lg={{ span: 8, offset: 0 }}
          id="social"
        >
          <p>Retrouvez-nous sur les réseaux sociaux</p>
          <a href="https://www.linkedin.com/company/strateg-in-company/">
            <LinkedinOutlined />
          </a>
          <a href="https://facebook.com/strateginofficiel/">
            <FacebookOutlined />
          </a>
          <a href="https://github.com/strategin">
            <GithubOutlined />
          </a>
          <a href="https://twitter.com/Strateg_in">
            <TwitterOutlined />
          </a>
        </Col>
        <Col
          xs={24}
          md={{ span: 12, offset: 0 }}
          lg={{ span: 8, offset: 0 }}
          className="table-contact"
        >
          <table align="center" className="tabcontact">
            <tbody>
              <tr>
                <td witdh="20px">
                  <PhoneOutlined />
                </td>
                <td align="left">
                  <a href="tel:+335561811879">+33 (0) 5 61 81 18 79</a>
                </td>
              </tr>
              <tr>
                <td witdh="20px">
                  <MailOutlined />
                </td>
                <td align="left">
                  <a href="mailto:contact@strateg.in">contact@strateg.in</a>
                </td>
              </tr>
              <tr>
                <td width="50px">
                  <EnvironmentOutlined />
                </td>
                <td align="left">
                  54 rue Bayard
                  <br />
                  31000 Toulouse
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <Row justify="center" className="menu">
        <Link to="/offres">Nos offres</Link>
        <Divider type="vertical" id="footer-divider" />
        <Link to="/recrutement">Recrutement</Link>
        <Divider type="vertical" id="footer-divider" />
        <Link to="/blog">Blog</Link>
        <Divider type="vertical" id="footer-divider" />
        <Link to="/mentions-legales">Mentions légales</Link>
        <Divider type="vertical" id="footer-divider" />
        <Link to="/politique-de-confidentialite">
          Politique de confidentialité
        </Link>
      </Row>
      <Row>
        <Col xs={{ span: 16, offset: 4 }} md={{ span: 16, offset: 4 }}>
          <p id="copyr">Powered by Strateg.in</p>
        </Col>
      </Row>
    </div>
  );
};
export default Footer;
