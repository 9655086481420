import React from 'react';
import styled from 'styled-components';
import image from '../../assets/images/mentionslegales.webp';
import Bandeau from '../Bandeau/bandeau.jsx';

const Text = styled.div`
  margin-left: 100px;
  margin-right: 100px;
  text-align: center;

  @media (max-width: 900px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const MentionsLegales = () => {
  return (
    <div>
      <Bandeau image={image} title="Mentions Légales" />
      <Text style={{ marginTop: 50 }}>
        Merci de lire avec attention les différentes modalités d’utilisation du
        présent site avant de le parcourir. En vous connectant sur ce site, vous
        acceptez sans réserves les présentes modalités. Aussi, conformément à
        l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance
        dans l’économie numérique, les responsables du présent site internet
        http://www.strateg.in sont :{' '}
      </Text>
      <h3 id="offre_barre">Editeur du Site</h3>
      <Text>
        SASU STRATEG IN N°SIRET : 825 025 406 00018 Responsable éditorial :
        Yohan Rousseau Email : contact@strateg.in Site Web :
        http://www.strateg.in
      </Text>
      <h3 id="offre_barre">Hébergeur du site</h3>
      <Text>
        <strong>Hébergeur :</strong>
      </Text>
      <Text>ionos – 7 pl. de la gare 57200 Sarreguemines 0970808911</Text>

      <h3 id="offre_barre">Directeur de la publication</h3>
      <Text>
        <strong>Nom du Directeur de la publication :</strong> Yohan Rousseau
      </Text>
      <Text>
        <strong>Adresse e-mail :</strong>{' '}
        <a href="mailto:contact@strateg.in">contact@strateg.in</a>
      </Text>

      <h3 id="offre_barre">Propriété Intellectuelle</h3>
      <Text>
        Le contenu présent sur le site{' '}
        <a href="https://strateg.in">www.strateg.in</a> (textes, images, vidéos,
        graphiques, logos, icônes, sons, logiciels, etc.) est protégé par les
        lois relatives à la propriété intellectuelle et est la propriété
        exclusive de Strateg In. Toute reproduction, représentation,
        modification, publication, adaptation, intégrale ou partielle, des
        éléments du site, quel que soit le moyen ou le procédé utilisé, est
        interdite, sauf autorisation écrite préalable de Strateg In.
      </Text>

      <h3 id="offre_barre">Protection des Données Personnelles</h3>
      <Text>
        Conformément à la loi Informatique et Libertés n° 78-17 du 6 janvier
        1978 modifiée, et au Règlement Général sur la Protection des Données
        (RGPD), vous disposez d’un droit d’accès, de rectification, de
        portabilité et d’effacement de vos données personnelles.
      </Text>
      <Text>
        Pour exercer ces droits, vous pouvez contacter :{' '}
        <a href="mailto:contact@strateg.in">contact@strateg.in</a>
      </Text>
      <Text>
        Les données personnelles collectées sur le site sont uniquement
        destinées à un usage interne et ne seront en aucun cas vendues ou cédées
        à des tiers.
      </Text>

      <h3 id="offre_barre">Cookies</h3>
      <Text>
        Le site utilise des cookies pour améliorer l’expérience de navigation et
        recueillir des données statistiques anonymes sur la fréquentation du
        site. En poursuivant votre navigation sur le site, vous acceptez
        l’utilisation de ces cookies. Vous pouvez configurer votre navigateur
        pour désactiver les cookies à tout moment.
      </Text>

      <h3 id="offre_barre">Limitation de Responsabilité</h3>
      <Text>
        Le site <a href="https://www.strateg.in">www.strateg.in</a> s’efforce de
        fournir des informations aussi précises que possible. Toutefois, il ne
        pourra être tenu responsable des omissions, inexactitudes ou carences
        dans la mise à jour, qu’elles soient de son fait ou du fait des tiers
        partenaires qui lui fournissent ces informations.
      </Text>

      <h3 id="offre_barre">Droit Applicable</h3>
      <Text>
        Les présentes mentions légales sont régies par la législation française.
        En cas de litige, et à défaut de solution amiable, compétence est
        attribuée aux tribunaux compétents.
      </Text>
    </div>
  );
};

export default MentionsLegales;
