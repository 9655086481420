import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Exception from '../components/Exceptions/Exception';
import useAuthContext from '../contexts/AuthContext';

import TopHeader from '../components/Menu/Header';
import Footer from '../components/Footer/Footer';
import ScrollToTop from '../components/ScrollTop/ScrollToTop';

import Home from '../components/home/Home';
import Blog from '../components/Blog/BlogVue';
import EquipesPartenaires from '../components/Equipes/EquipesPartenairesVue';
import Recrutement from '../components/Recrutement/Recrutement';
import OffreDetail from '../components/Recrutement/DetailOffre';
import OffresEmplois from '../components/Recrutement/OffresEmplois';
import Candidature from '../components/Recrutement/Candidature';
import BlogPost from '../components/Blog/BlogPost';
import Contact from '../components/Accueil/Contact';
import Offres from '../components/Offres/Offres';
import GenieLogiciel from '../components/Offres/GenieLogiciel';
import EditeurLogiciel from '../components/Offres/EditeurLogiciel';
import ConseilAndFormation from '../components/Offres/ConseilAndFormation';
import MentionLegales from '../components/Accueil/MentionsLegales';
import PrivacyPolicy from '../components/Accueil/PrivacyPolicy';

export const PrivateRoute = ({ component: Component, ...restProps }) => {
  const authContext = useAuthContext();

  return (
    <Route
      {...restProps}
      render={props =>
        authContext.isValid ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string })
};

PrivateRoute.defaultProps = {
  location: { pathname: '/' }
};

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <TopHeader />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/offres" component={Offres} />
        <Route exact path="/genie-logiciel" component={GenieLogiciel} />
        <Route excat path="/editeur-logiciel" component={EditeurLogiciel} />
        <Route excat path="/conseil" component={ConseilAndFormation} />
        <Route exact path="/blog" component={Blog} />
        <Route path="/blog/:id" component={BlogPost} />
        <Route exact path="/equipes" component={EquipesPartenaires} />
        <Route exact path="/recrutement" component={Recrutement} />
        <Route exact path="/recrutement/offres" component={OffresEmplois} />
        <Route path="/recrutement/offres/:id" component={OffreDetail} />
        <Route exact path="/recrutement/candidature" component={Candidature} />
        <Route exact path="/mentions-legales" component={MentionLegales} />
        <Route
          exact
          path="/politique-de-confidentialite"
          component={PrivacyPolicy}
        />
        <PrivateRoute component={Exception} />
      </Switch>
      <Contact />
      <Footer />
    </BrowserRouter>
  );
};

export default Router;
