import React from 'react';
import styled from 'styled-components';
import image from '../../assets/images/mentionslegales.webp';
import Bandeau from '../Bandeau/bandeau.jsx';

const Text = styled.div`
  margin-left: 100px;
  margin-right: 100px;
  text-align: center;

  @media (max-width: 900px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const MentionsLegales = () => {
  return (
    <div>
      <Bandeau image={image} title="Politique de confidentialité" />
      <h3 id="offre_barre">Conformité aux réglementations</h3>
      <Text>
        Conformément aux dispositions de la Loi n° 78-17 du 6 janvier 1978
        relative à l’informatique, aux fichiers et aux libertés, et au Règlement
        (UE) 2016/679 du 27 avril 2016, nous nous engageons à protéger vos
        données personnelles et à garantir leur confidentialité.
      </Text>

      <h3 id="offre_barre">Définitions</h3>
      <h4 id="sous-titre">Données personnelles</h4>
      <Text>
        Toute information ou donnée relative à une personne physique identifiée
        ou identifiable, directement ou indirectement, notamment par référence à
        un identifiant.
      </Text>

      <h4 id="sous-titre">Traitement</h4>
      <Text>
        Toute opération ou ensemble d’opérations effectuées sur des données
        personnelles, telles que la collecte, l’enregistrement, l’organisation,
        la conservation, la modification, l’extraction, la consultation,
        l’utilisation, la communication, l’effacement ou la destruction.
      </Text>

      <h4 id="sous-titre">Cookies</h4>
      <Text>
        Désigne un bloc de données qui n’est pas utilisé à des fins
        d’identification mais sert à enregistrer des informations relatives à la
        navigation sur le site.
      </Text>

      <h4 id="sous-titre">Sous-traitant</h4>
      <Text>
        La personne physique ou morale, l’autorité publique, le service ou un
        autre organisme qui traite des données personnelles pour le compte du
        responsable du traitement.
      </Text>

      <h4 id="sous-titre">Engagement de Strateg In</h4>
      <Text>
        Chez Strateg In, nous accordons la plus grande importance à la
        protection de vos données personnelles. Nous veillons à respecter les
        principes de confidentialité et de sécurité pour toutes les informations
        que vous nous confiez.
      </Text>

      <h3 id="offre_barre">Identité du responsable du traitement</h3>
      <Text>
        Strateg In 54, rue de Bayard 31000 TOULOUSE N°SIRET : 825 025 406 00018
      </Text>

      <h3 id="offre_barre">Données collectées</h3>
      <Text>
        Nous collectons différentes catégories de données personnelles,
        notamment vos informations d’identification, coordonnées et informations
        de paiement.
      </Text>

      <h3 id="offre_barre">Origine des données</h3>
      <Text>
        Les données sont collectées directement via les formulaires présents sur
        notre site et indirectement par l’utilisation de cookies et d’autres
        traceurs.
      </Text>

      <h3 id="offre_barre">Finalités du traitement des données</h3>
      <Text>
        Vos données personnelles sont traitées pour la gestion des commandes,
        l’amélioration de nos services, la personnalisation de l’expérience
        utilisateur et la conformité légale.
      </Text>

      <h3 id="offre_barre">Base légale du traitement</h3>
      <Text>
        Les bases légales de notre traitement de vos données sont le
        consentement, l’exécution d’un contrat, l’intérêt légitime et le respect
        d’une obligation légale.
      </Text>

      <h3 id="offre_barre">Durée de conservation des données</h3>
      <Text>
        Vos données personnelles sont conservées pendant la durée nécessaire à
        la réalisation des finalités pour lesquelles elles ont été collectées.
      </Text>

      <h3 id="offre_barre">Destinataires des données</h3>
      <Text>
        Vos données personnelles peuvent être partagées avec des partenaires,
        sous-traitants et autorités compétentes lorsque la loi l’exige.
      </Text>

      <h3 id="offre_barre">Transfert de données hors Union Européenne</h3>
      <Text>
        Vos données personnelles peuvent être transférées en dehors de l’Union
        Européenne dans le cadre de l’exécution de certaines prestations. Nous
        nous assurons que ces transferts respectent les garanties appropriées.
      </Text>

      <h3 id="offre_barre">Sécurité des données</h3>
      <Text>
        Nous mettons en œuvre toutes les mesures techniques et
        organisationnelles appropriées pour garantir la sécurité et la
        confidentialité de vos données.
      </Text>

      <h3 id="offre_barre">Vos droits</h3>
      <Text>
        En vertu du RGPD, vous disposez des droits d’accès, de rectification,
        d’opposition, d’effacement, de limitation et de portabilité de vos
        données personnelles.
      </Text>

      <h3 id="offre_barre">Gestion des cookies</h3>
      <Text>
        Nous utilisons des cookies pour améliorer l’expérience utilisateur et
        analyser le trafic sur notre site.
      </Text>

      <h3 id="offre_barre">Types de cookies utilisés</h3>
      <Text>
        Cookies strictement nécessaires : indispensables pour le bon
        fonctionnement du site. Cookies analytiques : permettent de mesurer
        l’audience et d’analyser la navigation.Cookies marketing : utilisés pour
        personnaliser la publicité.
      </Text>

      <h3 id="offre_barre">Modification de la politique de confidentialité</h3>
      <Text>
        Nous nous réservons le droit de modifier cette politique de
        confidentialité à tout moment. Toute modification sera publiée sur cette
        page.
      </Text>
    </div>
  );
};

export default MentionsLegales;
